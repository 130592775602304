import React, { useState } from 'react'
import { node, string } from 'prop-types'
import { IconArrow2, IconArrow1 } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/global-icons'
import {
  AccordionContainer,
  Title,
  ChildrenContainer,
} from './accordion.styles'

const propTypes = {
  title: string,
  children: node,
}

function Accordion({ title, children }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <AccordionContainer>
      <Title
        variant="bodyXlLight"
        color="neutral.90"
        component="h3"
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      >
        {title}
        <GlobalIcons
          Icon={isOpen ? IconArrow1 : IconArrow2}
          size="large"
          color="#000000"
        />
      </Title>
      <ChildrenContainer isOpen={isOpen}>{children}</ChildrenContainer>
    </AccordionContainer>
  )
}

Accordion.propTypes = propTypes

export default Accordion
