import {
  styled,
  selectTheme,
  media,
  Typography,
  Inlinelink,
} from '@creditas-ui/react'

export const Container = styled.section`
  background: ${selectTheme('colors.neutral.0')};
  width: 100%;
`

export const FaqContainer = styled.div`
  max-width: 1366px;
  margin-inline: auto;
  padding: 48px 0;
  ${media.between('5xl', '8xl')} {
    margin-inline: 71px;
  }
  ${media.down('4xl')} {
    padding: 96px 20px 56px;
  }
`

export const TitleContainer = styled.div`
  padding-bottom: 40px;
  border-bottom: 4px solid ${selectTheme('colors.neutral.90')};
`

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 8px;
  padding-top: 3px;
  width: 32px;
  height: 32px;
`

export const ArrowImage = styled.img`
  display: block;
  width: 32px;
  height: 32px;
`

export const Paragraph = styled(Typography)`
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: ${selectTheme('colors.primary.90')};
    font-weight: 700;
  }
`

export const ListContainer = styled.ul`
  margin-left: 22px;
  margin-bottom: 15px;
  li::marker {
    font-size: 15px;
  }
`

export const ListTitle = styled(Typography)`
  a,
  a:visited {
    color: ${selectTheme('colors.neutral.90')};
    text-decoration: none;
    word-break: break-all;
  }
`

export const Link = styled(Inlinelink)`
  margin-top: 32px;
  padding: 16px 16px 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  max-width: 260px;
`
