import { styled, Typography } from '@creditas-ui/react'

export const AccordionContainer = styled.div`
  border-bottom: 1px solid #dbe1dd;
`

export const Title = styled(Typography)`
  width: 100%;
  padding: 33px 0 ${({ isOpen }) => (isOpen ? 10 : 33)}px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: ${({ isOpen }) => (isOpen ? 700 : 300)};
  :hover {
    text-decoration: underline;
  }
  -webkit-tap-highlight-color: transparent;
`

export const ChildrenContainer = styled.div`
  padding-bottom: ${({ isOpen }) => (isOpen ? 10 : 0)}px;
  max-height: ${({ isOpen }) => (isOpen ? '1200px' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: all 0.2s ease-in 0s;
  visibility: initial;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`
