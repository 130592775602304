import React, { useEffect } from 'react'
import { array, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas-ui/react'
import { IconArrow } from '@creditas-ui/icons'
import { formatQueryParams } from 'utils/formatQueryParams'
import Accordion from './Accordion'
import {
  ArrowContainer,
  Container,
  FaqContainer,
  ListContainer,
  ListTitle,
  TitleContainer,
  Paragraph,
  Link,
} from './faqs.styles'

const propTypes = {
  questions: array,
  linkRedirection: string,
}

const Faqs = ({ questions, linkRedirection }) => {
  const { t } = useTranslation()
  const actionLink = t('faqs:faqSection:actionLinkText')

  useEffect(() => {
    if (window.location.hash.includes('#faq')) {
      document.getElementById('faq').scrollIntoView()
    }
  }, [])

  return (
    <Container id="faq">
      <FaqContainer>
        <TitleContainer>
          <Typography
            color="neutral.90"
            variant="headingMdLight"
            component="h2"
          >
            {t('faqs:faqSection:title')}
          </Typography>
        </TitleContainer>
        {questions.map((item, indexQ) => (
          <Accordion title={item.question} key={indexQ}>
            {item.answer.map((component, indexA) => (
              <div key={indexA}>
                {component.paragraph && (
                  <Paragraph
                    variant="bodyLgLight"
                    color="neutral.90"
                    dangerouslySetInnerHTML={{
                      __html: component.paragraph,
                    }}
                  />
                )}
                {component.list && (
                  <>
                    <ListTitle
                      variant="bodyLgBold"
                      color="neutral.90"
                      dangerouslySetInnerHTML={{
                        __html: component.list.title,
                      }}
                    />
                    <ListContainer>
                      {component.list.bullets?.map((bullet, indexB) => (
                        <Typography
                          key={indexB}
                          component="li"
                          color="neutral.90"
                          variant="bodyLgLight"
                        >
                          {bullet}
                        </Typography>
                      ))}
                    </ListContainer>
                  </>
                )}
              </div>
            ))}
          </Accordion>
        ))}
        <Link href={formatQueryParams(linkRedirection)} target="_blank">
          <Typography color="primary.90" variant="bodyLgBold">
            {actionLink}
          </Typography>
          <ArrowContainer>
            <IconArrow
              size="30"
              color="#0F7C50"
              strokeWidth="1.5"
              alt={t('faqs:faqSection:arrowAlt')}
            />
          </ArrowContainer>
        </Link>
      </FaqContainer>
    </Container>
  )
}

Faqs.propTypes = propTypes

export default Faqs
